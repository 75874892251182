import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";
import type { FunctionComponent } from "react";

export const HashLockup: FunctionComponent<SvgIconProps> = ({
  sx,
  ...props
}) => {
  return (
    <SvgIcon
      width="100"
      height="18"
      viewBox="0 0 100 18"
      sx={[
        {
          "&:hover": { opacity: 0.8 },
          transition: "opacity 0.1s ease-in-out",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <path
        d="M25.0742 0.537109H29.738V6.87204H35.7239V0.537109H40.3877V17.4625H35.7239V11.0308H29.738V17.4625H25.0742V0.537109Z"
        fill="#4D5C6C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6069 0.537109H51.1023L43.9238 17.5197H48.8379L50.0424 14.5086H56.5464L57.7749 17.5197H62.7853L55.6069 0.537109ZM53.3185 6.07753L55.1974 10.8712H51.4154L53.3185 6.07753Z"
        fill="#4D5C6C"
      />
      <path
        d="M72.9478 17.4625C71.4323 17.4625 69.9934 17.2365 68.631 16.7845C67.2687 16.3326 66.0636 15.6546 65.0156 14.7507L67.6274 11.735C69.3203 13.0441 71.1501 13.6987 73.1171 13.6987C73.7458 13.6987 74.2255 13.6013 74.556 13.4065C74.8865 13.2117 75.0517 12.935 75.0517 12.5766V12.5298C75.0517 12.3584 75.0114 12.2064 74.9308 12.0739C74.8502 11.9415 74.7011 11.8129 74.4834 11.6882C74.2658 11.5635 73.9756 11.4428 73.6128 11.3259C73.2501 11.209 72.7785 11.0882 72.1981 10.9635C71.2952 10.7609 70.4569 10.5388 69.683 10.2973C68.9091 10.0557 68.2361 9.74399 67.6637 9.36215C67.0914 8.98031 66.6399 8.50497 66.3094 7.93612C65.9789 7.36726 65.8137 6.66204 65.8137 5.82044V5.77369C65.8137 5.01002 65.9628 4.3087 66.2611 3.66971C66.5593 3.03072 66.9946 2.47745 67.567 2.0099C68.1393 1.54234 68.8285 1.17999 69.6347 0.92284C70.4408 0.665685 71.3517 0.537109 72.3674 0.537109C73.8184 0.537109 75.1122 0.720232 76.2488 1.08648C77.3854 1.45273 78.4132 1.99431 79.3322 2.71123L76.9864 5.91395C76.2125 5.38406 75.4185 4.98275 74.6043 4.71001C73.7902 4.43727 73.0042 4.3009 72.2465 4.3009C71.6822 4.3009 71.259 4.4022 70.9768 4.60481C70.6947 4.80742 70.5536 5.05678 70.5536 5.35289V5.39965C70.5536 5.58667 70.598 5.75031 70.6866 5.89058C70.7753 6.03084 70.9285 6.15942 71.1461 6.27631C71.3638 6.3932 71.662 6.51008 72.0409 6.62697C72.4198 6.74386 72.8994 6.86464 73.4798 6.98932C74.4472 7.19193 75.3218 7.4296 76.1037 7.70234C76.8857 7.97508 77.5507 8.30626 78.0988 8.69589C78.647 9.08552 79.0662 9.55696 79.3564 10.1102C79.6466 10.6635 79.7917 11.322 79.7917 12.0856V12.1324C79.7917 12.974 79.6264 13.7221 79.2959 14.3766C78.9654 15.0312 78.5019 15.5884 77.9054 16.0481C77.3089 16.5079 76.5914 16.8586 75.7531 17.1001C74.9147 17.3417 73.9796 17.4625 72.9478 17.4625Z"
        fill="#4D5C6C"
      />
      <path
        d="M83.9102 0.537109H88.5739V6.87204H94.5599V0.537109H99.2236V17.4625H94.5599V11.0308H88.5739V17.4625H83.9102V0.537109Z"
        fill="#4D5C6C"
      />
      <path
        opacity="0.886277"
        d="M10.0918 18H15.1036V0H10.0918V18Z"
        fill="#0775E3"
      />
      <path
        opacity="0.898345"
        d="M2.89648 18H7.90794V0H2.89648V18Z"
        fill="#0775E3"
      />
      <path
        opacity="0.881278"
        d="M0 7.71497H18V2.70312H0V7.71497Z"
        fill="#0775E3"
      />
      <path
        opacity="0.855632"
        d="M0 15.0388H18V10.0273H0V15.0388Z"
        fill="#0775E3"
      />
    </SvgIcon>
  );
};
