import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";
import type { FunctionComponent } from "react";

export const CanvasIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="16" height="12" viewBox="0 0 16 12" fill="none" {...props}>
      <path d="M2.72461 10.1859H9.74023C10.0684 10.705 10.4785 11.1432 10.9707 11.4984C10.8887 11.4984 10.8066 11.4984 10.7246 11.4984H2.72461C1.74023 11.4984 0.974609 10.7319 0.974609 9.74841V2.24857C0.974609 1.29238 1.74023 0.498607 2.72461 0.498607H8.23633C8.70117 0.498607 13.772 0.485367 14.2246 0.487547H13.7074C14.7767 0.638396 15.0246 1.48795 15.0246 1.99856V3.20882C14.4797 2.95647 14.3244 2.96294 13.7109 2.98748L13.7074 1.81108H2.72461C2.47852 1.81108 2.28711 2.03001 2.28711 2.24857V9.74841C2.28711 9.99429 2.47852 10.1859 2.72461 10.1859Z" />
      <path d="M2.72461 10.1859H9.74023C10.0684 10.705 10.4785 11.1432 10.9707 11.4984C10.8887 11.4984 10.8066 11.4984 10.7246 11.4984H2.72461C1.74023 11.4984 0.974609 10.7319 0.974609 9.74841V2.24857C0.974609 1.29238 1.74023 0.498607 2.72461 0.498607H8.23633C8.70117 0.498607 13.772 0.485367 14.2246 0.487547H13.7074C14.7767 0.638396 15.0246 1.48795 15.0246 1.99856V3.20882C14.4797 2.95647 14.3244 2.96294 13.7109 2.98748L13.7074 1.81108H2.72461C2.47852 1.81108 2.28711 2.03001 2.28711 2.24857V9.74841C2.28711 9.99429 2.47852 10.1859 2.72461 10.1859Z" />
      <path d="M13.275 1.81424H6.25937C5.93125 1.29517 5.52109 0.856925 5.02891 0.501769C5.11094 0.501769 5.19297 0.501769 5.275 0.501769H13.275C14.2594 0.501769 15.025 1.26822 15.025 2.25173V9.75158C15.025 10.7078 14.2594 11.5015 13.275 11.5015H7.76328C7.29844 11.5015 2.22762 11.5148 1.775 11.5126H2.29226C1.22291 11.3617 0.975 10.5122 0.975 10.0016V8.79132C1.51988 9.04368 1.67517 9.03721 2.28867 9.01266L2.29226 10.1891H13.275C13.5211 10.1891 13.7125 9.97013 13.7125 9.75158V2.25173C13.7125 2.00585 13.5211 1.81424 13.275 1.81424Z" />
      <path d="M13.275 1.81424H6.25937C5.93125 1.29517 5.52109 0.856925 5.02891 0.501769C5.11094 0.501769 5.19297 0.501769 5.275 0.501769H13.275C14.2594 0.501769 15.025 1.26822 15.025 2.25173V9.75158C15.025 10.7078 14.2594 11.5015 13.275 11.5015H7.76328C7.29844 11.5015 2.22762 11.5148 1.775 11.5126H2.29226C1.22291 11.3617 0.975 10.5122 0.975 10.0016V8.79132C1.51988 9.04368 1.67517 9.03721 2.28867 9.01266L2.29226 10.1891H13.275C13.5211 10.1891 13.7125 9.97013 13.7125 9.75158V2.25173C13.7125 2.00585 13.5211 1.81424 13.275 1.81424Z" />
    </SvgIcon>
  );
};
