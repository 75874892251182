import { typedEntries, typedKeys } from "@local/advanced-types/typed-entries";
import { isArrayMetadata, isObjectMetadata, isValueMetadata, } from "@local/hash-graph-types/entity";
import { isBaseUrl } from "@local/hash-graph-types/ontology";
const typeId = Symbol.for("@local/hash-graph-sdk/entity/SerializedEntity");
const isSerializedEntity = (entity) => {
    return ("entityTypeId" in
        entity.metadata);
};
const isGraphApiEntity = (entity) => {
    return ("entityTypeIds" in
        entity.metadata);
};
export const propertyObjectToPatches = (object) => typedEntries(object.value).map(([propertyTypeBaseUrl, property]) => {
    return {
        op: "add",
        path: [propertyTypeBaseUrl],
        property,
    };
});
/**
 * Creates an array of PropertyPatchOperations that, if applied, will transform the oldProperties into the
 * newProperties.
 *
 * @deprecated this is a function for migration purposes only.
 *    For new code, track which properties are actually changed where they are changed, and create the patch operations
 *   directly. IF you use this, bear in mind that newProperties MUST represent ALL the properties that the entity will
 *   have after the patch. Any properties not specified in newProperties will be removed.
 */
export const patchesFromPropertyObjects = ({ oldProperties, newProperties, }) => {
    const patches = [];
    for (const [key, property] of typedEntries(newProperties.value)) {
        if (typeof oldProperties[key] !== "undefined" &&
            oldProperties[key] !== property.value) {
            patches.push({
                op: "replace",
                path: [key],
                property,
            });
        }
        else {
            patches.push({
                op: "add",
                path: [key],
                property,
            });
        }
    }
    for (const key of typedKeys(oldProperties)) {
        if (typeof newProperties.value[key] === "undefined") {
            patches.push({
                op: "remove",
                path: [key],
            });
        }
    }
    return patches;
};
/**
 * Return a helper function for the given Properties object and patches, which can be called with a BaseUrl valid for
 * that object, and will return the new value for that BaseUrl defined in the provided list of patches, or undefined if
 * no new value has been set.
 *
 * The 'new value' is defined as the value for the first 'add' or 'replace' operation at that BaseUrl.
 * NOT supported:
 *  - the net effect of multiple operations on the same path
 *  - nested paths / array paths
 *
 * If you want to see if a value has been _removed_, see {@link isValueRemovedByPatches}
 *
 * An alternative implementation could avoid the need for an inner function, by requiring that the Key was specified as
 * a generic: export const getDefinedPropertyFromPatches = < Properties extends PropertyObject, Key extends keyof
 * Properties,
 * > => { ... }
 *
 * const newValue = getDefinedPropertyFromPatches<Properties, "https://example.com/">({ propertyPatches, baseUrl:
 * "https://example.com/" });
 *
 * This alternative is more tedious if you need to check for multiple properties, as (1) each key must be specified as
 * both a generic and as an argument, and (2) the propertyPatches provided each time. Unimplemented TS proposal partial
 * type argument inference would solve (1) but not (2).
 */
export const getDefinedPropertyFromPatchesGetter = (propertyPatches) => {
    return (baseUrl) => {
        const foundPatch = propertyPatches.find((patch) => patch.path[0] === baseUrl);
        if (!foundPatch || foundPatch.op === "remove") {
            return;
        }
        return foundPatch.property.value;
    };
};
export const isValueRemovedByPatches = ({ baseUrl, propertyPatches, }) => {
    return propertyPatches.some((patch) => patch.op === "remove" && patch.path[0] === baseUrl);
};
/**
 * @hidden
 * @deprecated - For migration purposes only.
 */
export const mergePropertiesAndMetadata = (property, metadata) => {
    if (Array.isArray(property)) {
        if (!metadata) {
            return {
                value: property.map((element) => mergePropertiesAndMetadata(element, undefined)),
                metadata: undefined,
            };
        }
        if (isArrayMetadata(metadata)) {
            return {
                value: property.map((element, index) => mergePropertiesAndMetadata(element, metadata.value[index])),
                metadata: metadata.metadata,
            };
        }
        if (isObjectMetadata(metadata)) {
            throw new Error(`Expected metadata to be an array, but got metadata for property object: ${JSON.stringify(metadata, null, 2)}`);
        }
        // Metadata is for a value, so we treat the property as a value
        return {
            value: property,
            metadata: metadata.metadata,
        };
    }
    if (typeof property === "object" && property !== null) {
        if (!metadata) {
            const returnedValues = {};
            let isPropertyObject = true;
            for (const [key, value] of typedEntries(property)) {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- It's possible for values to be undefined
                if (value === undefined) {
                    continue;
                }
                if (!isBaseUrl(key)) {
                    isPropertyObject = false;
                    break;
                }
                returnedValues[key] = mergePropertiesAndMetadata(value, undefined);
            }
            if (isPropertyObject) {
                // we assume that the property is a property object if all keys are base urls.
                // This is not strictly the case as the property could be a value object with base urls as
                // keys, but we don't have a way to distinguish between the two.
                return {
                    value: returnedValues,
                };
            }
            // If the keys are not base urls, we treat the object as a value
            return {
                value: property,
                metadata: {
                    dataTypeId: null,
                },
            };
        }
        if (isObjectMetadata(metadata)) {
            return {
                value: Object.fromEntries(Object.entries(property)
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- It's possible for values to be undefined
                    .filter(([_key, value]) => value !== undefined)
                    .map(([key, value]) => {
                    if (!isBaseUrl(key)) {
                        throw new Error(`Expected property key to be a base URL, but got ${JSON.stringify(key, null, 2)}`);
                    }
                    return [
                        key,
                        mergePropertiesAndMetadata(value, metadata.value[key]),
                    ];
                })),
                metadata: metadata.metadata,
            };
        }
        if (isArrayMetadata(metadata)) {
            throw new Error(`Expected metadata to be an object, but got metadata for property array: ${JSON.stringify(metadata, null, 2)}`);
        }
        // Metadata is for a value, so we treat the property as a value
        return {
            value: property,
            metadata: metadata.metadata,
        };
    }
    // The property is not an array or object, so we treat it as a value
    if (!metadata) {
        return {
            value: property,
            metadata: {
                dataTypeId: null,
            },
        };
    }
    if (isValueMetadata(metadata)) {
        return {
            value: property,
            metadata: metadata.metadata,
        };
    }
    if (isArrayMetadata(metadata)) {
        throw new Error(`Expected metadata to be for a value, but got metadata for property array: ${JSON.stringify(metadata, null, 2)}`);
    }
    else {
        throw new Error(`Expected metadata to be for a value, but got metadata for property object: ${JSON.stringify(metadata, null, 2)}`);
    }
};
/**
 * @hidden
 * @deprecated - For migration purposes only.
 */
export const mergePropertyObjectAndMetadata = (property, metadata) => {
    return {
        value: Object.fromEntries(Object.entries(property)
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- It's possible for values to be undefined
            .filter(([_key, value]) => value !== undefined)
            .map(([key, value]) => {
            if (!isBaseUrl(key)) {
                throw new Error(`Expected property key to be a base URL, but got ${JSON.stringify(key, null, 2)}`);
            }
            return [key, mergePropertiesAndMetadata(value, metadata?.value[key])];
        })),
        metadata: metadata?.metadata,
    };
};
export const flattenPropertyMetadata = (metadata) => {
    const flattened = [];
    const visitElement = (path, element) => {
        if ("value" in element) {
            if (Array.isArray(element.value)) {
                for (const [index, value] of element.value.entries()) {
                    visitElement([...path, index], value);
                }
            }
            else {
                for (const [key, value] of typedEntries(element.value)) {
                    visitElement([...path, key], value);
                }
            }
        }
        if (element.metadata) {
            flattened.push({
                path,
                metadata: element.metadata,
            });
        }
    };
    visitElement([], metadata);
    return flattened;
};
/**
 * Retrieves a `ClosedMultiEntityType` from a given response based on a list of entity type IDs.
 *
 * @param response - The response object returned from the Graph API which contains the closed multi-entity types.
 * @param entityTypesIds - An array of entity type IDs.
 * @returns Returns a `ClosedMultiEntityType` if found, otherwise returns `undefined`.
 */
export const getClosedMultiEntityTypesFromResponse = (response, entityTypesIds) => {
    // A response does not necessarily include closed multi-entity types.
    if (!response.closedMultiEntityTypes) {
        return;
    }
    // The `closedMultiEntityTypes` field contains a nested map of closed entity types.
    // At each depth the map contains a `schema` field which contains the closed multi-entity type
    // up to that depth. The `inner` field contains the next level of nested maps.
    // The nested keys are always sorted after the keys of the current depth.
    //
    // For example: If an entity type ID is `["https://example.com/1", "https://example.com/2"]`
    // the nested map would look like this:
    // {
    //    "https://example.com/1": {
    //      "schema": <Closed schema of "https://example.com/1">
    //      "inner": {
    //        "https://example.com/2": {
    //          "schema": <Closed schema of "https://example.com/1" and "https://example.com/2">
    //          "inner": {
    //            ...
    //         }
    //       }
    //    }
    // }
    //
    // Thus, we sort the entity type IDs and traverse the nested map to find the closed multi-entity type.
    // The first entity type ID is used to get the first level of the nested map. The rest of the entity
    // type IDs are used to traverse the nested map.
    const [firstEntityTypeId, ...restEntityTypesIds] = entityTypesIds.toSorted();
    return restEntityTypesIds.reduce((map, entity_type_id) => map?.inner?.[entity_type_id], response.closedMultiEntityTypes[firstEntityTypeId])?.schema;
};
export class Entity {
    #entity;
    constructor(entity) {
        if (isSerializedEntity(entity)) {
            this.#entity = entity;
        }
        else if (isGraphApiEntity(entity)) {
            this.#entity = {
                ...entity,
                properties: entity.properties,
                metadata: {
                    ...entity.metadata,
                    recordId: entity.metadata.recordId,
                    entityTypeIds: entity.metadata
                        .entityTypeIds,
                    temporalVersioning: entity.metadata
                        .temporalVersioning,
                    properties: entity.metadata.properties,
                    provenance: {
                        ...entity.metadata.provenance,
                        createdById: entity.metadata.provenance.createdById,
                        createdAtDecisionTime: entity.metadata.provenance
                            .createdAtDecisionTime,
                        createdAtTransactionTime: entity.metadata.provenance
                            .createdAtTransactionTime,
                        firstNonDraftCreatedAtDecisionTime: entity.metadata.provenance
                            .firstNonDraftCreatedAtDecisionTime,
                        firstNonDraftCreatedAtTransactionTime: entity.metadata.provenance
                            .firstNonDraftCreatedAtTransactionTime,
                        edition: {
                            ...entity.metadata.provenance.edition,
                            createdById: entity.metadata.provenance.edition
                                .createdById,
                            archivedById: entity.metadata.provenance.edition
                                .archivedById,
                        },
                    },
                },
                linkData: entity.linkData
                    ? {
                        ...entity.linkData,
                        leftEntityId: entity.linkData.leftEntityId,
                        rightEntityId: entity.linkData.rightEntityId,
                    }
                    : undefined,
            };
        }
        else {
            throw new Error(`Expected entity to be either a serialized entity, or a graph api entity, but got ${JSON.stringify(entity, null, 2)}`);
        }
    }
    static async create(graphAPI, authentication, params) {
        return (await Entity.createMultiple(graphAPI, authentication, [params]))[0];
    }
    static async createMultiple(graphAPI, authentication, params) {
        return graphAPI
            .createEntities(authentication.actorId, params.map(({ entityTypeIds, draft, provenance, ...rest }) => ({
            entityTypeIds,
            draft: draft ?? false,
            provenance: {
                ...provenance,
                origin: {
                    ...provenance.origin,
                    // ProvidedEntityEditionProvenanceOriginTypeEnum is not generated correctly in the hash-graph-client
                    type: provenance.origin
                        .type,
                },
            },
            ...rest,
        })))
            .then(({ data: entities }) => entities.map((entity, index) => {
            return new Entity(entity);
        }));
    }
    static async validate(graphAPI, authentication, params) {
        return await graphAPI
            .validateEntity(authentication.actorId, params)
            .then(({ data }) => data);
    }
    async patch(graphAPI, authentication, { entityTypeIds, propertyPatches, provenance, ...params }) {
        return graphAPI
            .patchEntity(authentication.actorId, {
            entityId: this.entityId,
            entityTypeIds,
            properties: propertyPatches,
            provenance: {
                ...provenance,
                origin: {
                    ...provenance.origin,
                    // @ts-expect-error –– ProvidedEntityEditionProvenanceOriginTypeEnum is not generated correctly in the hash-graph-client
                    type: provenance.origin.type,
                },
            },
            ...params,
        })
            .then(({ data }) => new Entity(data));
    }
    async archive(graphAPI, authentication, provenance) {
        await graphAPI.patchEntity(authentication.actorId, {
            entityId: this.entityId,
            archived: true,
            provenance: {
                ...provenance,
                origin: {
                    ...provenance.origin,
                    // @ts-expect-error –– ProvidedEntityEditionProvenanceOriginTypeEnum is not generated correctly in the hash-graph-client
                    type: provenance.origin.type,
                },
            },
        });
    }
    async unarchive(graphAPI, authentication, provenance) {
        await graphAPI.patchEntity(authentication.actorId, {
            entityId: this.entityId,
            archived: false,
            provenance: {
                ...provenance,
                origin: {
                    ...provenance.origin,
                    // @ts-expect-error –– ProvidedEntityEditionProvenanceOriginTypeEnum is not generated correctly in the hash-graph-client
                    type: provenance.origin.type,
                },
            },
        });
    }
    get metadata() {
        return this.#entity.metadata;
    }
    get entityId() {
        return this.#entity.metadata.recordId.entityId;
    }
    get properties() {
        return this.#entity.properties;
    }
    /**
     * @hidden
     * @deprecated - For migration purposes only.
     */
    get propertiesWithMetadata() {
        return mergePropertyObjectAndMetadata(this.#entity.properties, this.#entity.metadata.properties);
    }
    get propertiesMetadata() {
        return this.#entity.metadata.properties ?? { value: {} };
    }
    propertyMetadata(path) {
        return path.reduce((map, key) => {
            if (!map || !("value" in map)) {
                return undefined;
            }
            if (typeof key === "number") {
                if (Array.isArray(map.value)) {
                    return map.value[key];
                }
                else {
                    return undefined;
                }
            }
            else if (!Array.isArray(map.value)) {
                return map.value[key];
            }
            else {
                return undefined;
            }
        }, this.#entity.metadata.properties)?.metadata;
    }
    flattenedPropertiesMetadata() {
        return flattenPropertyMetadata(this.#entity.metadata.properties ?? { value: {} });
    }
    get linkData() {
        return this.#entity.linkData;
    }
    toJSON() {
        return { [typeId]: typeId, ...this.#entity };
    }
    get [Symbol.toStringTag]() {
        return this.constructor.name;
    }
}
export class LinkEntity extends Entity {
    constructor(entity) {
        const input = (entity instanceof Entity ? entity.toJSON() : entity);
        if (!input.linkData) {
            throw new Error(`Expected link entity to have link data, but got \`${input.linkData}\``);
        }
        super(input);
    }
    static async createMultiple(graphAPI, authentication, params) {
        return graphAPI
            .createEntities(authentication.actorId, params.map(({ entityTypeIds, draft, provenance, ...rest }) => ({
            entityTypeIds,
            draft: draft ?? false,
            provenance: {
                ...provenance,
                origin: {
                    ...provenance.origin,
                    // ProvidedEntityEditionProvenanceOriginTypeEnum is not generated correctly in the hash-graph-client
                    type: provenance.origin
                        .type,
                },
            },
            ...rest,
        })))
            .then(({ data: entities }) => entities.map((entity) => new LinkEntity(entity)));
    }
    static async create(graphAPI, authentication, params) {
        return (await LinkEntity.createMultiple(graphAPI, authentication, [params]))[0];
    }
    async patch(graphAPI, authentication, { entityTypeIds, propertyPatches, provenance, ...params }) {
        return graphAPI
            .patchEntity(authentication.actorId, {
            entityId: this.entityId,
            entityTypeIds,
            properties: propertyPatches,
            provenance: {
                ...provenance,
                origin: {
                    ...provenance.origin,
                    // @ts-expect-error –– ProvidedEntityEditionProvenanceOriginTypeEnum is not generated correctly in the hash-graph-client
                    type: provenance.origin.type,
                },
            },
            ...params,
        })
            .then(({ data }) => new LinkEntity(data));
    }
    get linkData() {
        return super.linkData;
    }
}
