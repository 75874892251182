import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";
import type { FunctionComponent } from "react";

export const InboxIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 19 18" fill="none">
      <path
        d="M1.5 15.0001C1.5 15.5626 1.9375 16.0001 2.5 16.0001H14.5C15.0312 16.0001 15.5 15.5626 15.5 15.0001V12.2501C15.5 12.1876 15.4688 12.0939 15.4688 12.0314V12.0001H12.2812L11.5625 13.4689C11.4062 13.8126 11.0625 14.0001 10.6875 14.0001H6.28125C5.90625 14.0001 5.5625 13.8126 5.40625 13.4689L4.6875 12.0001H1.53125V12.0314C1.5 12.0939 1.5 12.1876 1.5 12.2501V15.0001ZM15.2188 11.0001L14.4375 7.89076L15.5312 8.15639L17.5 7.00014V3.00022L14.5 1.5L12 3.00014L11.5625 4.00014H4.3125C3.84375 4.00014 3.4375 4.31264 3.3125 4.78139L1.78125 11.0001H4.6875C5.0625 11.0001 5.40625 11.2189 5.5625 11.5626L6.28125 13.0001H10.6875L11.4062 11.5626C11.5625 11.2189 11.9062 11.0001 12.2812 11.0001H15.2188ZM0.5 12.2501C0.5 12.0939 0.5 11.9376 0.53125 11.7814L2.34375 4.53139C2.59375 3.62514 3.375 3.00014 4.3125 3.00014H12L14.5 1.5L17.5 3.00022V7.00014L15.5312 8.15639L16.4375 11.7814C16.4688 11.9376 16.5 12.0939 16.5 12.2501V15.0001C16.5 16.1251 15.5938 17.0001 14.5 17.0001H2.5C1.375 17.0001 0.5 16.1251 0.5 15.0001V12.2501Z"
        fill="currentColor"
      />
      <path
        d="M10.5 5C10.5 3.57812 11.25 2.26562 12.5 1.54688C13.7344 0.828125 15.25 0.828125 16.5 1.54688C17.7344 2.26562 18.5 3.57812 18.5 5C18.5 6.4375 17.7344 7.75 16.5 8.46875C15.25 9.1875 13.7344 9.1875 12.5 8.46875C11.25 7.75 10.5 6.4375 10.5 5ZM14.8906 7.01562L16.6406 5.26562C16.7812 5.125 16.7812 4.89062 16.6406 4.73438L14.8906 3C14.7344 2.84375 14.5 2.84375 14.3594 3C14.2031 3.14062 14.2031 3.375 14.3594 3.51562L15.4688 4.625H12.625C12.4062 4.625 12.25 4.79688 12.25 5C12.25 5.21875 12.4062 5.375 12.625 5.375L15.4688 5.39062L14.3594 6.5C14.2031 6.64062 14.2031 6.875 14.3594 7.01562C14.5 7.17188 14.7344 7.17188 14.8906 7.01562Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
